import React, { useContext, useRef, useState, useEffect } from "react"; 

import { 
    ContentBody,
    BodyPrincipal,
    BodyMenu, 
    Spacer,
    CenterLoadMore
} from './styled' 

import ButtonMenu from "../../components/MenuButton"; 
import VideoCategory from "../../components/VideoCategory";
import Banner from "../../components/Banner"; 

import { availableContents, availableCatagories, availableVideos } from "../../services/mock"; 
import { CoreContext } from "../../context/CoreContext"; 

import { ReadHome, ReadHomeTabs, ReadKeepWatching, ReadTabCatalog, ReadTabPlaylist } from "../../services/catalog";
import { parseBanner, parseCategory, parseCollection, parseVideo } from "../../utils/parser";
import { Load } from "../../ui/styled";
import { normalizeStrapiRegister } from "../../utils";
import VideoButton from "../VideoButton";

export default function PageHome({ setFocus }){ 

    const {currentProfile, authentication, side, setSide, moreCategory, setMoreCategory } = useContext(CoreContext)
 

    const [banners, setBanners] = useState([])
    const [tabs, setTabs] = useState([])
    const [loading, setLoading] = useState(false)
    const [currentCategory, setCurrentCategory] = useState(0)
    const [categoryCollections, setCategoryCollections] = useState([])


    const action = (item, key) => {
        if(item.id === 5){
            setSide({ type: 'side-more', tabs })
        }else{
            setCurrentCategory(key)
        }
    }

    const sliderRef = useRef(null); 

    const [categories, setCategories] = useState([
        availableContents[0],
    ])                   
    
    const initTabs = () => {
        if(tabs?.length){
            const homeTabs = [
                availableContents[0],
                ...[...tabs].slice(0, 3).map(parseCategory)
            ]
            if( tabs?.length > 3 ){ homeTabs.push(availableContents[1]) ;}
            setCategories(homeTabs)
        }
    } 

    const init = async () => {
        const home = await ReadHome()
        const tabs = await ReadHomeTabs()

        if(home?.data?.attributes?.featured?.length){
            setBanners(home?.data?.attributes?.featured?.map(parseBanner))
        } 

        if(tabs?.data?.length){ 
            setTabs((currentProfile?.kids) ? [...tabs?.data].filter(f => f.attributes.name === "Kids") : [...tabs?.data])
        } 

    }

    const showMoreCategory = async () => {
        setLoading(true)
        const result = await ReadTabCatalog(moreCategory?.id)
        if(result?.data?.length){
            setCategoryCollections(result?.data?.map(parseCollection)) 
        }else{
            setCategoryCollections([])
        }
        setLoading(false) 
        setMoreCategory(null)
    }

    const initCategory = async () => {
        if((currentCategory > 0 && currentCategory < 4) && !tabs[currentCategory-1]?.id){ return ;}
        if(loading) return;
        // console.log("WH ", tabs[currentCategory-1], currentCategory)

        setLoading(true)
        const requests = []
        if(currentCategory === 0){
            requests.push(ReadTabPlaylist())
        }
        if(currentCategory > 0 && currentCategory < 4){
            if(tabs[currentCategory-1]?.id){
                requests.push(ReadTabCatalog(tabs[currentCategory-1]?.id))
            }
        }
        if(currentCategory === 4){
            // showing more, nothing to do
        }
        let result =  await Promise.all(requests)

        if(currentProfile?.kids){
            result[0].data = result?.[0]?.data?.filter(f => f?.attributes?.name === "Kids")
        }

        if(result?.[0]?.data?.length){

            const resultWatch = await ReadKeepWatching(currentProfile?.id, authentication?.jwt)
            // const videosWatching = (resultWatch?.data||[])?.filter(ff => !normalizeStrapiRegister(ff)?.data?.finishing)?.map(mm => parseVideo(normalizeStrapiRegister(mm)?.stream_show)||[])?.reduce((p, c) => p.map(m => m.id)?.includes(c.id) ? p : [...p, c], [])

            const videosWatching = (resultWatch?.data||[])
            // ?.sort((a, b) => new Date(b?.attributes?.updatedAt).getTime() - new Date(a?.attributes?.updatedAt).getTime()  )
                                        ?.map(mm => {
                                            const normalRegister = normalizeStrapiRegister(mm)
                                            // console.log("normalRegister", normalRegister)
                                            if(!normalRegister?.stream_show || !!normalRegister?.data?.finishing) return false;
                                            return parseVideo(normalRegister?.stream_show);
                                        })?.filter(f => f)
                                    ?.reduce((p, c) => p.map(m => m.id)?.includes(c.id) ? p : [...p, c], [])


            const watched = {
                id: 2,
                image: null,
                title: "Continuar assistindo",
                videos: videosWatching || []
            }

            console.log('Collections', result?.[0]?.data, result?.[0]?.data?.map(parseCollection))
            setCategoryCollections(watched?.videos?.length && currentCategory === 0 ? [
                watched,
                ...result?.[0]?.data?.map(parseCollection)
            ]: result?.[0]?.data?.map(parseCollection)) 
        }else{
            setCategoryCollections([])
        }
        setLoading(false)
    }
 
    useEffect(() => {
        init()
    }, [currentProfile])
 
    useEffect(() => {
        if(moreCategory?.id){
            showMoreCategory()
        }
    }, [moreCategory])
 
    useEffect(() => {
        initTabs()
    }, [tabs])

    useEffect(() => {
        initCategory()
    }, [currentCategory, tabs, currentProfile]) 



    const pageSize = 2;
    const [page, setPage] = useState(1)
    const [loadingMore, setLoadingMore] = useState(false)

    let timerMore = useRef().current
    let timerMoreAction = useRef().current

    const loadMore = () => { 
        if(  (categoryCollections?.length >= ( pageSize*(page) ) ) ){
            clearTimeout(timerMoreAction)
            timerMoreAction = setTimeout(() => {
                clearTimeout(timerMore)
                setLoadingMore(true)
                console.log("Handle More")
                timerMore = setTimeout(() => {
                    setPage( page + 1 )
                    setLoadingMore(false)
                }, 5000)
            }, 1500)
        }
    }   

    return (
        <> 
            <ContentBody ref={sliderRef}>
                <Banner banners={banners} /> 
                <BodyPrincipal>
                    {
                        loading ? <>
                            <Spacer />
                            <Load /> 
                        </> : <>
                            <BodyMenu> 
                                {
                                    categories.map((item, key) =>
                                        <ButtonMenu key={key} item={item} onPress={() => action(item, key)} onEnterPress={() => action(item, key)} />  
                                    )
                                } 
                            </BodyMenu> 
                            {
                                categoryCollections?.slice(0, (page * pageSize))?.map((item, key) => 
                                    <VideoCategory 
                                        key={key} 
                                        item={item} 
                                        availableVideos={item.videos} 
                                        availableCatagories={categoryCollections} 
                                        verticalRef={sliderRef} 
                                        verticalSize={page * pageSize}
                                        verticalLoadMore={loadMore}
                                        verticalKey={key} /> 
                                )
                            }
                        </>
                    }


                    { loadingMore ? 
                        <CenterLoadMore>
                            <VideoButton loading /> 
                        </CenterLoadMore>
                    : null }
                </BodyPrincipal>
            </ContentBody> 
        </>
    )
}