import React, { useCallback, useContext, useEffect } from "react";
import { useNavigate } from 'renative';

import {
    Container,
    ContentLogo,
    LogoIcon,
    LogoText,

    ContentWelcome,
    WelcomeTitle,
    
    ContentProfiles,
    LogoImage,
    AccessButton,
    AccessButtonText,
    ButtonExitIcon,
    // ProfileItem,
    // ProfileItemImage,
    // ProfileItemText
} from './styled'

import { View } from 'react-native';
import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import { hasWebFocusableUI } from '../../../config';
import { CoreContext } from "../../context/CoreContext";
import { availableUsers } from "../../services/mock";
import ProfileItem from "../../components/ProfileItem";
import useRemoteControl from "../../hooks/useRemoteControl";

// const FocusableView = hasWebFocusableUI ? withFocusable()(View) : View;

export default withFocusable({
    trackChildren: true,
    forgetLastFocusedChild: false, 
})(function ConfirmExit(props){

    const navigate = useNavigate(props);
    const { 
        next, 
        setNext, 
        currentProfile,
        setCurrentVideo,
        setFavorites,
        setCurrentProfile,
        setProfiles,
        setUser,
        setSide,
        setAuthentication
    } = useContext(CoreContext)
  
    const cancel = () => { 
        navigate(currentProfile ? '/catalog' : '/select-profile')
    }

    const exit = () => {

        setCurrentVideo(null) 
        setFavorites([]) 
        setCurrentProfile(null) 
        setProfiles([])  
        setUser(null)
        setSide(null) 
        setAuthentication(null)
    
        navigate('/')
    }



    const handleRemote = useCallback( code => { 
        if(code === 10009 || code === 461 || code === 27 || code === 8 ){ 
            cancel()
        }
        if( code === 10182 ){ 
            exit()
        }
    }, [])

   const { pressed } = useRemoteControl(handleRemote)

    useEffect(() => {  
        props.setFocus('access-2')
        if(next){
            setNext(null)
            navigate(next)
        }
    }, []);

    useEffect(() => {
        const preventBack = (e) => {
          e.preventDefault();
          window.history.forward();
        };
        window.history.pushState(null, null, window.location.pathname);
        window.addEventListener('popstate', preventBack);
        return () => {
          window.removeEventListener('popstate', preventBack);
        };
      }, []);
    
    return props.path !== "/confirm-exit" ? null : (
        <>
            <Container> 

                <LogoImage />
                {
                    next ? null : 
                    <ContentWelcome>
                        <WelcomeTitle>Deseja realmente sair?</WelcomeTitle>

                        <ContentProfiles>
                            

                            <AccessButton onPress={exit} onEnterPress={exit} focusKey={'access-1'}> 
                                <ButtonExitIcon />
                                <AccessButtonText>
                                    Sair
                                </AccessButtonText>
                            </AccessButton>
                            <AccessButton active onPress={cancel} onEnterPress={cancel} focusKey={'access-2'}> 
                                <AccessButtonText>
                                    Ficar na HoxxTV
                                </AccessButtonText>
                            </AccessButton>

                        </ContentProfiles>
                    </ContentWelcome> 
                }

            </Container>
        </>
    )
});