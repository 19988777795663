import React, { useCallback, useContext, useEffect } from "react";
import { useNavigate } from 'renative';

import {
    Container,
    ContentLogo,
    LogoIcon,
    LogoText,

    ContentWelcome,
    WelcomeTitle,
    
    ContentProfiles,
    LogoImage,
    // ProfileItem,
    // ProfileItemImage,
    // ProfileItemText
} from './styled'

import { View } from 'react-native';
import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import { hasWebFocusableUI } from '../../../config';
import { CoreContext } from "../../context/CoreContext";
import { availableUsers } from "../../services/mock";
import ProfileItem from "../../components/ProfileItem";
import useRemoteControl from "../../hooks/useRemoteControl";

// const FocusableView = hasWebFocusableUI ? withFocusable()(View) : View;

export default withFocusable({
    trackChildren: true,
    forgetLastFocusedChild: false, 
})(function SelectProfile(props){

    const navigate = useNavigate(props);
    const { setCurrentProfile, profiles } = useContext(CoreContext)
  
    const next = item => {
        console.log('Access with user :: ', item )
        setCurrentProfile(item)
        navigate('/catalog')
    }

    const handleRemote = useCallback( code => { 
        if(code === 10009 || code === 461 || code === 27 || code === 8 || code === 10182 ){ 
            navigate("/confirm-exit") 
        }
    }, [])

   const { pressed } = useRemoteControl(handleRemote)

    useEffect(() => {  
        props.setFocus('profile-1')
    }, [profiles]);
    
    return props.path !== "/select-profile" ? null : (
        <>
            <Container> 

                <LogoImage />
                <ContentWelcome>
                    <WelcomeTitle>Quem vai assistir?</WelcomeTitle>
                </ContentWelcome> 

                    <ContentProfiles>
                        {
                            profiles.map((item, key) =>
                                <ProfileItem key={key} onEnterPress={() => next(item)} onPress={() => next(item)} item={item} focusKey={`profile-${ key+1 }`} />
                            )
                        }
                    </ContentProfiles>
                     

            </Container>
        </>
    )
});