
import { API_ENDPOINT } from "../services/api"
import { getScaledValue } from "../ui/styled"

const CryptoJS = require('crypto-js'); 
  
const profilesTitles = {
    male:'Masculino',
    female:'Feminino'
}

export const parseStrapiImage = url => {
    return url ? url?.indexOf('://') !== -1 ? url : url?.indexOf('hoxxtv.b-cdn.net') !== -1 ? `https://${url}` :  `${API_ENDPOINT.replace('/api', '')}${url}` : ''
}

export const parseProfile = item => {
    return {
        ...item, 
        id: item.id,
        name: item.name,
        image:{ uri: parseStrapiImage(item?.picture?.url) },
        gender:{ title:profilesTitles[item.gender], type:item.gender }
    }
}

export const parseProfileImage = item => {
    return {
        id: item.id,
        name: item.name,
        image:{ uri: parseStrapiImage(item.url) }
    }
}














const categoriesOption = {
    'Filme':{
        title:'Filme',
        color:'blue'
    },
    'Série':{
        title:'Série',
        color:'yellow'
    },

    'Musical':{
        title:'Musical',
        color:'blue'
    },
    'Educativo':{
        title:'Educativo',
        color:'green'
    }
}

const CDN_ENDPOINT = 'https://vz-0ca6c7c3-50f.b-cdn.net'

const getBunnyUrl = (path) => {
  const hashes = [ ...[...['c596d3c7', 'dab2'], ...['48fc', ...['93b4']], ...['15642c722816']]]
  const expires = Math.round(Date.now() / 1000) + 3600;
  const hashableBase = hashes.join('-') + path + expires; 

  let token = CryptoJS.SHA256(hashableBase).toString(CryptoJS.enc.Base64);
  token = token.replace(/\+/g, '-').replace(/\//g, '_').replace(/\=/g, ''); 

  const url = `${CDN_ENDPOINT}/bcdn_token=${ token }&expires=${expires}&token_path=${path}`  
  return url
}

const parseBunnyImage = url => {
    if( `${ url }`.indexOf(CDN_ENDPOINT) !== -1 ){
        const path = `${ url }`.replace(CDN_ENDPOINT, '')
        return getBunnyUrl(path)
    } 
    return url
}

export const parseBanner = item => { 
    const bunny_image = parseBunnyImage(item?.featured_show?.data?.attributes?.bunny_image)
    return { 
        id: item?.featured_show?.data?.id,
        title: item?.featured_show?.data?.attributes?.name,
        description: item?.featured_show?.data?.attributes?.description,
        image: { uri: bunny_image ? bunny_image : parseStrapiImage(item?.featured_banner?.data?.attributes?.url) },
        category: categoriesOption['Filme'] || {}
    }
}

const categoryIcons = {
    'Playlist':{ icon:'play', width:getScaledValue(9), height:getScaledValue(11), fill:'#ffffff' } ,
    'Conteúdos':{ icon:'smile', width:getScaledValue(15), height:getScaledValue(17), fill:'#f95e14' } ,
    'Educativos':{ icon:'paint', width:getScaledValue(14), height:getScaledValue(12), fill:'#f95e14' } ,
    'Filmes':{ icon:'popcorn', width:getScaledValue(14), height:getScaledValue(12), fill:'#f95e14' } ,
    'Ver Mais':{ icon:'plus', width:getScaledValue(14), height:getScaledValue(12), fill:'#ffffff' } ,
}


export const parseCategory = item => { 
    return { 
        title: item?.attributes?.name, 
        id: item?.id, 
        icon: categoryIcons[item?.attributes?.name] || {},
        image: item?.attributes?.icon?.data?.attributes?.url ? parseStrapiImage(item.attributes.icon.data.attributes.url) : null
    }
}


const collectionResources = {
    'Meus Favoritos':{ icon:{ icon:'liked', width:getScaledValue(22), height:getScaledValue(20), fill:'#f95e14' } },
    'As 20 Músicas + Vistas':{ icon:{ icon:'music', width:getScaledValue(17), height:getScaledValue(21), fill:'#f95e14' } },
    'Cante e Dance':{ icon:{ icon:'music', width:getScaledValue(17), height:getScaledValue(21), fill:'#f95e14' } },
}

export const parseCollection = item =>  {
    return {
        id: item?.id, 
        title: item?.attributes?.name, 
        ...(collectionResources[item?.attributes?.name] || {}),
        videos: item?.attributes?.stream_shows?.data?.length ? item?.attributes?.stream_shows?.data?.map(parseVideo) : [],
        image: item?.attributes?.icon?.data?.attributes?.url ? parseStrapiImage(item?.attributes?.icon?.data?.attributes?.url) : null
    }
}

export const parseVideo = item => {
    const bunny_image = parseBunnyImage(item?.attributes?.bunny_image || item?.bunny_image)
    return { 
        id: item?.id, 
        title: item?.attributes?.name || item?.name, 
        description: item?.attributes?.description || item?.description, 
        cover: { uri: bunny_image ? bunny_image : parseStrapiImage(item?.attributes?.thumbnail?.data?.attributes?.url || item?.thumbnail?.data?.attributes?.url || item?.thumbnail?.url) }, 
        // category_id:1 
    }
}

export const parseVideoShow = item => {
    const bunny_image = parseBunnyImage(item?.attributes?.bunny_image || item?.bunny_image)
    return { 
        ...item,
        title: item.name,
        cover: { uri: bunny_image ? bunny_image : parseStrapiImage(item?.attributes?.wide_picture?.data?.attributes?.url || item?.wide_picture?.data?.attributes?.url || item?.wide_picture?.url) }, 
        thumb: { uri: bunny_image ? bunny_image : parseStrapiImage(item?.attributes?.thumbnail?.data?.attributes?.url || item?.thumbnail?.data?.attributes?.url || item?.thumbnail?.url) }, 
        // category_id:1 
    }
}